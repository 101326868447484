.play-now-section-wrapper {
    height: calc(100vh + 100px);

    .section-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        background-image: url('./assets/images/play-now-section/img_dino.webp');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background-image: url('./assets/images/play-now-section/img_dino@2x.webp');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (min--moz-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-device-pixel-ratio: 3),
        only screen and (min-resolution: 288dpi),
        only screen and (min-resolution: 3dppx) {
            background-image: url('./assets/images/play-now-section/img_dino@3x.webp');
        }
    }

    .section-bg-parallax {
        width: 118%;
        height: 118%;
        position: absolute;

        top: -150px;
        left: -150px;

        z-index: -2;

        .section-bg-parallax-back,
        .section-bg-parallax-sky {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
        }

        .section-bg-parallax-back {
            background-image: url('./assets/images/play-now-section/img_back.webp');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min--moz-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-device-pixel-ratio: 2),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
                background-image: url('./assets/images/play-now-section/img_back@2x.webp');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min--moz-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-device-pixel-ratio: 3),
            only screen and (min-resolution: 288dpi),
            only screen and (min-resolution: 3dppx) {
                background-image: url('./assets/images/play-now-section/img_back@3x.webp');
            }
        }

        .section-bg-parallax-sky {
            background-image: url('./assets/images/play-now-section/img_sky.webp');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min--moz-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-device-pixel-ratio: 2),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
                background-image: url('./assets/images/play-now-section/img_sky@2x.webp');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min--moz-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-device-pixel-ratio: 3),
            only screen and (min-resolution: 288dpi),
            only screen and (min-resolution: 3dppx) {
                background-image: url('./assets/images/play-now-section/img_sky@3x.webp');
            }
        }

        @media only screen and (max-width: 991px) {
            width: 150%;
            height: 150%;
        }

    }

    .play-now-box {

        position: relative;
        height: 100px;
        margin-top: 71vh;
        width: 100%;

        .play-now-btn-box {

            display: flex;
            justify-content: center;

            .play-now-btn {
                width: 260px;
                height: 80px;


                background: linear-gradient(180deg, #E75300 11.46%, #FFC234 86.98%);
                box-shadow: inset 0px 8px 4px rgba(255, 234, 191, 0.6), inset 0px -3px 3px rgba(255, 223, 160, 0.32), inset 0px -8px 4px rgba(153, 31, 0, 0.45), inset 0px 0px 2px 6px rgba(255, 169, 0, 0.55);

                border: 4px solid #FFFFFF;
                filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.6));
                border-radius: 24px;

                font-family: 'Chelsy';
                font-style: normal;
                font-weight: 400;
                font-size: 48px;
                line-height: 68px;

                text-align: center;
                text-transform: uppercase;

                text-decoration: none;

                color: #FFFFFF;

                &:hover {
                    background: linear-gradient(180deg, #FF5C00 0%, #FFD634 86.98%);
                    box-shadow: inset 0px 8px 4px rgba(255, 234, 191, 0.6), inset 0px -3px 3px rgba(255, 223, 160, 0.32), inset 0px -8px 4px rgba(153, 31, 0, 0.45), inset 0px 0px 2px 6px rgba(255, 169, 0, 0.55);
                }

                &:active {
                    background: linear-gradient(180deg, #CC4900 11.87%, #F4AB17 100%);
                    box-shadow: inset 0px -8px 4px rgba(255, 234, 191, 0.6), inset 0px 3px 3px rgba(255, 223, 160, 0.32), inset 0px 8px 4px rgba(153, 31, 0, 0.45), inset 0px 0px 2px 6px rgba(153, 31, 0, 0.2);
                }
            }
        }
    }

    .play-now-box-wrapper-bg-gradient {
        height: 200px;
        width: 100%;
        left: 0;
        bottom: 0;
        position: absolute;

        .play-now-box-bg-gradient {
            width: calc(100% + 24px);
            height: 100%;
            position: absolute;
            z-index: -1;
            left: -12px;

            background-position: top;
            background-size: cover;
            background-repeat: round;

            background-image: url('./assets/images/play-now-section/gradient.webp');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min--moz-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-device-pixel-ratio: 2),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
                background-image: url('./assets/images/play-now-section/gradient@2x.webp');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min--moz-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-device-pixel-ratio: 3),
            only screen and (min-resolution: 288dpi),
            only screen and (min-resolution: 3dppx) {
                background-image: url('./assets/images/play-now-section/gradient@3x.webp');
            }
        }
    }

    @media only screen and (max-width: 991px) {
        height: calc(80vh + 100px);
    }
}

.play-now-box-tree-box {
    height: 300px;
    position: absolute;
    margin-top: -200px;
    left: 0;
    right: 0;
    width: 100%;
    min-width: 365px;
    z-index: -1;

    .tree {
        width: 33%;
        height: 100%;
        position: absolute;

        .image-box {
            width: 100%;
            height: 100%;

            .image {
                width: 100%;
                height: 100%;

                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }

        &.left {
            left: 0;
            top: 0;

            .image-box {
                .image {
                    background-position: left;
                    background-image: url('./assets/images/play-now-section/img_tree_left.webp');

                    @media only screen and (-webkit-min-device-pixel-ratio: 2),
                    only screen and (min--moz-device-pixel-ratio: 2),
                    only screen and (-o-min-device-pixel-ratio: 2/1),
                    only screen and (min-device-pixel-ratio: 2),
                    only screen and (min-resolution: 192dpi),
                    only screen and (min-resolution: 2dppx) {
                        background-image: url('./assets/images/play-now-section/img_tree_left@2x.webp');
                    }

                    @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3),
                    only screen and (min-resolution: 288dpi),
                    only screen and (min-resolution: 3dppx) {
                        background-image: url('./assets/images/play-now-section/img_tree_left@3x.webp');
                    }
                }
            }
        }

        &.right {
            right: 0;
            top: 0;

            .image-box {
                .image {
                    background-position: right;
                    background-image: url('./assets/images/play-now-section/img_tree_right.webp');

                    @media only screen and (-webkit-min-device-pixel-ratio: 2),
                    only screen and (min--moz-device-pixel-ratio: 2),
                    only screen and (-o-min-device-pixel-ratio: 2/1),
                    only screen and (min-device-pixel-ratio: 2),
                    only screen and (min-resolution: 192dpi),
                    only screen and (min-resolution: 2dppx) {
                        background-image: url('./assets/images/play-now-section/img_tree_right@2x.webp');
                    }

                    @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3),
                    only screen and (min-resolution: 288dpi),
                    only screen and (min-resolution: 3dppx) {
                        background-image: url('./assets/images/play-now-section/img_tree_right@3x.webp');
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        margin-top: -276px;
    }

    @media only screen and (max-width: 991px) {
        margin-top: -220px;
    }

    @media only screen and (max-width: 800px) {
        margin-top: -188px;

        .tree {
            width: 43%;
        }
    }
}